<template>
  <div class="sim-order">
    <div class="mine-title">{{$t('route.sim_order')}}
      <span>{{$t('mine.tips')}}</span>
      <span><router-link to="/download" class="colorBtn">{{$t('mine.app')}}</router-link></span>
    </div>
    <van-tabs v-model="currentStatus" color="#2564F6" title-active-color="#2564F6" title-inactive-color="#666666"
     animated swipeable :ellipsis="false" @change="onChangeTabHandler">
      <van-tab :title="item.title" :name="item.status" v-for="(item,index) in tabs" :key="index">
        <a-spin size="large" :spinning="loading">
          <div class="table-page-table-wrapper">
            <a-table 
              :columns="columns" 
              row-key="id"
              size="middle" 
              :pagination="pagination" 
              :data-source="orders"
              @change="handleTableChange"
              :locale="{emptyText: $t('mine.empty')}"
              :scroll="{ x: 900 }"
              >
              <template slot="product" slot-scope="text,record">
                <a-space>
                  <div class="product-img">
                    <img :src="record.image||record.icon" alt="" srcset="">
                  </div>
                  <div class="two-line-ellipsis" :title="record.district_name + ' ' + record.spec">
                    {{ record.district_name }}
                    {{ record.spec }}
                  </div>
                </a-space>
              </template>
              <template slot="add_time" slot-scope="text,record">
                <span>
                  {{ record.add_time | timeFilter }}
                </span>
              </template>
              <template slot="pay_amount" slot-scope="text,record">
                <span>
                  {{ record.currency }}
                  {{ record.pay_amount }}
                </span>
              </template>
              <template slot="order_type" slot-scope="text,record">
                <span>{{ record.agency_id > 0 ? $t('common.pay_15') : $t('common.pay_14')}}</span>
              </template>
               <template slot="action" slot-scope="text,record">
                <div v-if="record.status == 0" 
                @click="onPayHandler(record)"
                style="color: #B50000; cursor: pointer;"
                >
                  {{$t("mine.order_8")}}
                </div>
                <div v-if="record.status == 2"
                 @click="onConfirm(record.order_no)"
                 style="cursor: pointer;"
                 >
                 {{$t("mine.order_13")}}
                </div>
                <div v-if="record.status == 2 ||record.status == 4"
                 @click="onDetailsClick(record.order_no)"
                 style="cursor: pointer;"
                 >
                 {{$t('mine.order_12')}}
                </div>
              </template>
            </a-table>
          </div>
          <div class="order-xs-wrapper">
            <div style="margin: 20px 0;">
              <van-card
                v-for="(item,index) in orders" :key="index"
                :currency="item.currency"
                :num="item.number"
                :price="item.pay_amount"
                :desc="item.add_time | timeFilter"
                :title="item.district_name + item.spec + item.days_text"
                :thumb="item.image||item.icon"
              >
                <template #tags>
                  <a-space>
                    <van-tag plain :type="item.status == 0 ? 'danger' : 'primary '">{{item.status_text}}</van-tag>
                    <van-tag plain type="primary" v-if="item._status.payType">{{item._status.payType}}</van-tag>
                  </a-space>
                </template>
                <template #footer>
                  <van-button size="mini" color="#B50000" v-if="item.status == 0" @click="onPayHandler(item)">
                    {{$t("mine.order_8")}}
                  </van-button>
                  <van-button size="mini" v-if="item.status == 2" @click="onConfirm(item.order_no)">
                    {{$t("mine.order_13")}}
                  </van-button>
                  <van-button size="mini" v-if="item.status == 2 ||item.status == 4" @click="onDetailsClick(item.order_no)">
                    {{$t("mine.order_12")}}
                  </van-button>
                </template>
              </van-card>
            </div>
            <a-empty v-show="!orders.length" :image="emptyImage" :description="$t('mine.order_10')"/>
            <div class="flex" v-show="!loading">
              <a-pagination simple @change="handlePageChange" v-model="pagination.current" :defaultPageSize="pagination.pageSize" :total="pagination.total" />
            </div>
          </div>
        </a-spin>
      </van-tab>
    </van-tabs>
    <van-overlay :show="detailsShow" :lock-scroll="false" ref='copy' z-index="1000">
      <div class="wrapper" >
        <div class="block" >
          <van-icon color="#666666" class="close" @click="detailsShow = false" style="cursor: pointer;" size="20px" name="cross" />
          <div class="modal-cont">
            <div class="title">{{$t("mine.order_17")}}</div>
            <div class="order-info">
              <div class="item row">
                  <span class="subtitle col-4">{{$t("mine.order_14")}}</span>
                  <span class="text col-8">{{traces.kd_name}}</span>
              </div>
              <div class="item row">
                    <span class="subtitle col-4">{{$t("mine.order_15")}}</span>
                    <span class="text col-6">{{traces.kd_no}}</span>
                    <span class="copy col-2" @click="copy">{{$t("mine.order_18")}}</span>
              </div>
              <div class="express">
                <span class="subtitle">{{$t("mine.order_16")}}</span>
                <div class="list">
                <van-steps direction="vertical" active-color="#2564f6" inactive-color="#A3A3A3" :active="0">
                  <van-step v-for="(item,index) in traces.steps" :key="index">
                    <h3> <span v-if="item.accept_address">【{{item.accept_address}}】</span>{{item.remark}}</h3>
                    <p>{{item.accept_time}}</p>
                  </van-step>
                </van-steps>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>
<script>
import BigNumber from 'bignumber.js';
import moment from 'moment';
import Clipboard from 'clipboard'; 
import { message,Empty } from 'ant-design-vue';
// 状态：0，待付款 1，待发货，2，待收货，3，待自提
export default {
  name:'SimOrder',
  data() {
    return {
      emptyImage:Empty.PRESENTED_IMAGE_SIMPLE,
      detailsShow:false,
      traces:{
        kd_no:'',
        kd_name:'',
        steps:[],
      },
      tabs:[
        {title:this.$t('mine.tab_1'),status:'all'},
        {title:this.$t('mine.tab_2'),status:0},
        {title:this.$t('mine.tab_3'),status:1},
        {title:this.$t('mine.tab_4'),status:2},
        // {title:this.$t('mine.tab_5'),status:3},
      ],
      currentStatus:'all',
      loading:false,
      orders:[],
      pagination:{
        current: 1,
        pageSize: 5,
        total:0
      },
    }
  },
  computed:{
    columns(){
      return [
        { title: this.$t("mine.order_1"),dataIndex: 'district_name', scopedSlots: { customRender: 'product' },width: 180 },
        { title: this.$t("common.title_4"),dataIndex: 'number' },
        { title: this.$t("mine.order_2"),dataIndex: 'add_time', scopedSlots: { customRender: 'add_time' } },
        { title: this.$t("mine.order_3"),dataIndex: 'pay_amount', scopedSlots: { customRender: 'pay_amount' } },
        { title: this.$t("mine.order_11"),dataIndex: 'order_type', scopedSlots: { customRender: 'order_type' } },
        { title: this.$t("mine.order_4"),dataIndex: 'status_text', },
        { title: this.$t("mine.order_5"),dataIndex: '_status.payType'},
        { title: this.$t("mine.order_6"),dataIndex: 'action', scopedSlots: { customRender: 'action' } },
      ]
    }
  },
  filters:{
    timeFilter(text){
      return moment.unix(text).format('YYYY-MM-DD HH:mm:ss')
    }
  },
  mounted(){
		this.init()
  },
  methods:{
    init(){
      this.getOrders()
    },
    async getOrders(){
      this.loading = true
      const status = this.currentStatus
      const { pageSize } = this.pagination
      const { data } = await this.$http.post(this.APIURL.ORDER.SIMLIST,{...(status!=='all'&&{status}),limit:pageSize,page:1},false)
      this.loading = false
      this.pagination.total = data.total
      this.orders = data.list
    },
    async handleTableChange(pagination){
      const status = this.currentStatus
      const { current, pageSize } = pagination
      this.pagination = pagination
      const { data } = await this.$http.post(this.APIURL.ORDER.SIMLIST,{...(status!=='all'&&{status}),limit:pageSize,page:current})
      this.orders = data.list
    },
    async handlePageChange(current, pageSize){
      const status = this.currentStatus
      this.pagination.current = current
      const { data } = await this.$http.post(this.APIURL.ORDER.SIMLIST,{...(status!=='all'&&{status}),limit:pageSize,page:current})
      this.orders = data.list
    },
    onChangeTabHandler(){
      this.pagination = {current: 1,pageSize: 5,total:0}
      this.getOrders()
    },
    onPayHandler(record){
      const { district_name,currency,plan_id,image,icon,price,spec,number,order_no,discount_amount,status } = record
      this.$router.push({ 
      name: 'Pay', 
      query: {
        type:0,
        status:toString(status),
        name:district_name,
        cover:image||icon,
        order_no,
        number,
        currency,
        plan_id,
        price:BigNumber(price).minus(8),
        spec,
        ...discount_amount&&{discount_amount}
      }});
    },
    onDetailsClick(order_no){
      this.$http.post(this.APIURL.ORDER.TRACES,{
         order_no:order_no,
         order_type:0
      }).then(res=>{
        if(res.code==1){
          this.traces = res.data.traces
          this.detailsShow =true
        }else{
          message.warning(res.msg)
        }
      })
    },
    copy() {  
        let copy=this.$refs.copy // 添加
        let clipboard =new Clipboard(copy, {
          text: (trigger)=>{
              return this.traces.kd_no;
          }
        });
        clipboard.on('success', e => {  
          // 释放内存  
          message.success(this.$t('mine.copy_1'))  
          clipboard.destroy()  
        })  
        clipboard.on('error', e => {  
          // 不支持复制  
          message.warning(this.$t('mine.copy_2'))  
          console.log('该浏览器不支持自动复制')  
          // 释放内存  
          clipboard.destroy()  
        })  
    },
    onConfirm(order_no){
      this.$dialog.confirm({
      title: this.$t('mine.order_13'),
      confirmButtonText:this.$t('mine.order_13'),
      cancelButtonText:this.$t('common.cancel'),
      }).then(() => {
         this.$http.post(this.APIURL.ORDER.COMPLETE,{order_no}
         ).then(res=>{
            if(res.code==1){
              message.success(res.msg)
              this.getOrders()
            }else{
              message.error(res.msg)
            }
          })
      }).catch(() => {
        // on cancel
      });
    },
  }
}
</script>
<style lang="scss" scoped>

</style>